import React from "react"
import { Flex, Box } from "rebass"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"

const AnwendungsgebietePage = () => (
  <Layout>
    <SEO title="Über mich" />

    <Flex
      flexWrap='wrap'
      px={[0, 4]}
      py={4}
      style={{
        backgroundColor: '#efe7e3',
      }}>
      <Box
        px={4}
        paddingTop={4}
        bg='white'
        width={[1, 1/2]}>
        <Title>Kontakt</Title>

        <p>Sie können mich zu den Sprechzeiten telefonisch oder per E-Mail erreichen.</p>

        <p><a
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: '#a02118', textDecoration: 'none' }}
          href="https://www.google.com/maps?ll=50.12038,8.66939&z=16&t=m&hl=de-DE&gl=US&mapclient=embed&daddr=Anna+von+Kl%C3%BCchtzner+Telemannstra%C3%9Fe+11+60323+Frankfurt+am+Main@50.12038,8.66939">Privatpraxis Anna von Klüchtzner<br />
        Telemannstr. 11<br />
        60323 Frankfurt am Main</a></p>

        <p><span style={{ display: 'inline-block', width: 24 }}>T</span> <a href="tel:+496997266517" style={{ textDecoration: 'none', color: '#a02118' }}>+49 69 972 66 517</a><br />
        <span style={{ display: 'inline-block', width: 24 }}>M</span>  <a href="tel:+491786358930" style={{ textDecoration: 'none', color: '#a02118' }}>+49 178 63 58 930</a></p>

        <p><a href="mailto:info@tcm-annavonkluechtzner.de" style={{ textDecoration: 'none', color: '#a02118' }}>info@tcm-annavonkluechtzner.de</a><br />
        <a href="//www.tcm-annavonkluechtzner.de" style={{ textDecoration: 'none', color: '#a02118' }}>www.tcm-annavonkluechtzner.de</a></p>
      </Box>
      <Box
        style={{ lineHeight: 0 }}
        width={[1, 1/2]}>
        <iframe
          width="600"
          height="450"
          frameborder="0" style={{ border: 0, width: '100%' }}
          title="Google Maps"
          src="https://www.google.com/maps/embed/v1/place?q=anna%20von%20kl%C3%BCchtzner&key=AIzaSyCps97mtcWQUmfyNyv0_pSNCtTIHBRVE18" allowfullscreen />
      </Box>
    </Flex>
  </Layout>
)

export default AnwendungsgebietePage
